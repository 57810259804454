<template>
    <div
        class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100"
    >
        <div class="ui-layout-content--full ui-form-actions">
            <el-button
                type="primary"
                size="mini"
                plain
                icon="el-icon-plus"
                @click="addHandle"
                >{{ '新建' }}</el-button
            >
            <el-button
                type="primary"
                size="mini"
                plain
                icon="el-icon-edit"
                @click="editHandle"
                >{{ '编辑' }}</el-button
            >
            <el-button
                type="primary"
                size="mini"
                plain
                icon="el-icon-view"
                @click="viewHandle"
                >{{ '查看' }}</el-button
            >
            <el-button
                type="primary"
                size="mini"
                plain
                icon="el-icon-delete"
                @click="deleteHandle"
                >{{ '删除' }}</el-button
            >
        </div>

        <!--查询-->

        <div
            class="ui-layout-content--wide ui-layout-flex--1 ui-height-100 ui-overflow-y--auto tableform_resource_container"
        >
            <el-tree-table
                highlight-current-row
                size="mini"
                :data="treeData"
                :columns="columns"
                @current-change="selectHandle"
                style="overflow-y: auto;"
            ></el-tree-table>
        </div>
        <div class="ui-layout-content--full">
            <el-dialog
                v-bind="formDialog"
                v-if="formDialog.visible"
                :visible.sync="formDialog.visible"
                :close-on-click-modal="false"
            >
                <dictionary-form
                    :id="selection ? selection.id : null"
                    :parentCode="selection ? selection.code : null"
                    :type="type"
                    ref="dictionaryForm"
                ></dictionary-form>
                <span slot="footer">
                    <el-button @click="formDialog.visible = false">{{'取消'}}</el-button>
                    <el-button
                        @click="submitHandle"
                        v-if="type !== 'view'"
                        type="primary"
                        >{{ '确定' }}</el-button
                    >
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import DictionaryForm from './DictionaryForm';
import ElTreeTable from './ElTreeTable';
export default {
    name: 'DictionaryIndex',
    components: {
        DictionaryForm,
        ElTreeTable
    },
    data() {
        return {
            formDialog: {
                title: 'xx',
                visible: false,
                width: '500px'
            },
            type: 'add',
            selection: null,
            treeData: [],
            columns: [
                {
                    collapse: true,
                    prop: 'code',
                    label: 'Code'
                },
                {
                    prop: 'name',
                    label: '名称'
                },
                {
                    prop: 'value',
                    label: '值'
                },
                {
                    prop: 'rank',
                    label: '排序',
                    width: 100
                }
            ]
        };
    },
    computed: {},
    methods: {
        selectHandle(selection) {
            this.selection = selection;
        },
        preValidate() {
            if (this.selection == null || this.selection.id == null) {
                this.$message.warning('请先选择数据');
                return false;
            }
            return true;
        },
        addHandle() {
            this.formDialog.visible = true;
            this.formDialog.title = '新增字典项';
            this.type = 'add';
        },
        editHandle() {
            if (!this.preValidate()) {
                return;
            }
            this.formDialog.visible = true;
            this.formDialog.title = '编辑字典项';
            this.type = 'edit';
        },
        viewHandle() {
            if (!this.preValidate()) {
                return;
            }
            this.formDialog.visible = true;
            this.formDialog.title = '查看字典项';
            this.type = 'view';
        },
        deleteHandle() {
            if (!this.preValidate()) {
                return;
            }
            this.$confirm(
                '确认删除?',
                '警告',
                {
                    confirmButtonClass:
                        'el-button el-button--mini el-button--primary',
                    type: 'warning'
                }
            )
                .then(() => {
                    this.$client
                        .deleteDictionary(this.selection.id)
                        .then(() => {
                            this.$message.success('操作成功');
                            this.init();
                        })
                        .catch();
                })
                .catch();
        },
        submitHandle() {
            this.$refs.dictionaryForm.submitHandle(() => {
                this.formDialog.visible = false;
                this.init();
            });
        },
        init() {
            this.$client
                .listDictionary({})
                .then(result => {
                    this.treeData = result.data;
                    this.selection = null;
                })
                .catch();
        }
    },
    created() {
        this.init();
    }
};
</script>
