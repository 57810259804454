<template>
    <div>
        <el-form
            :class="viewFlag ? 'disabled' : ''"
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            label-position="top"
            :disabled="viewFlag"
        >
            <!-- <el-form-item :label="$t('dictionary.parentCode')" prop="type">
                <el-input v-model="formData.parentCode" :clearable=true></el-input>
            </el-form-item> -->
            <el-form-item label="父Code" prop="parentCode">
                <el-input v-model="formData.parentCode" disabled></el-input>
            </el-form-item>
            <el-form-item label="Code" prop="code">
                <el-input v-model="formData.code" :clearable=true></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="formData.name" :clearable=true></el-input>
            </el-form-item>
            <el-form-item label="值" prop="value">
                <el-input v-model="formData.value" :clearable=true></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="rank">
                <el-input-number v-model="formData.rank" :min=1 :max=10000 :controls="false" :precision="0" class="number-input-align--left" style="width:100%"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'DictionaryForm',
    components: {
    },
    data() {
        return {
            formData: {
                parentCode: this.parentCode,
                code: '',
                name: '',
                value: '',
                rank: 1
            }
        };
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        parentCode: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    computed: {
        formRules() {
            return {
                code: [
                    {
                        required: true,
                        message: '请输入Code',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 64,
                        message: '请输入1-64位字符',
                        trigger: 'change'
                    }
                ],
                name: [
                    {
                        min: 1,
                        max: 64,
                        message: '请输入1-64位字符',
                        trigger: 'change'
                    }
                ],
                value: [
                    {
                        min: 1,
                        max: 255,
                        message: '请输入1-255位字符',
                        trigger: 'change'
                    }
                ],
                rank: [
                    {
                        required: true,
                        message: '请输入排序值',
                        trigger: 'blur'
                    }
                ],

            };
        },
        editFlag() {
            return this.type === 'edit';
        },
        viewFlag() {
            return this.type === 'view';
        },
    },
    methods: {
        submitHandle(callBack) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const client =
                        this.type === 'add' ? this.$client.addDictionary : this.$client.editDictionary;
                    client(this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        Object.prototype.toString.call(callBack) === '[object Function]' && callBack(true);
                    }).catch();
                }
            });
        },
        initFormData() {
            if ((this.type === 'edit' || this.type === 'detail' || this.type === 'view') && this.id != null) {
                this.$client.getDictionary(this.id).then(reuslt => {
                    this.formData = reuslt.data;
                }).catch();
            }
        }
    },
    created() {
        this.initFormData();
    }
};
</script>
<style lang="less" scoped>
    .orgDeptSelect {
        width: 100%
    }
  .disabled{
    /deep/ .el-input__inner{
        border: 1px solid  #DDD !important;
        background-color: rgb(243, 241, 241) !important;
        color:rgb(51, 51, 50) !important;
    }
    /deep/ .el-textarea__inner{
        border: 1px solid  #DDD !important;
        background-color: rgb(243, 241, 241) !important;
        color:rgb(51, 51, 50) !important;
    }
  }
</style>
