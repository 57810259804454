<template>
    <el-table
        :data="tableListData"
        :row-style="toggleDisplayTr"
        border
        stripe
        v-bind="$attrs"
        v-on="$listeners"
        class
    >
        <template v-for="(column, index) in columns">
            <el-table-column :key="index" v-bind="column" v-if="column.collapse">
                <template slot-scope="scope">
                    <p
                        :style="`margin-left: ${scope.row.__level * 20}px;margin-top:0;margin-bottom:0`"
                    >
                        <i
                            @click="toggleFoldingStatus(scope.row)"
                            class="collapse-icon"
                            :class="toggleFoldingClass(scope.row)"
                        ></i>
                        {{scope.row[column.prop]}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column :key="index" v-bind="column" v-else></el-table-column>
        </template>
    </el-table>
</template>
<script>
export default {
    name: 'ElTreeTable',
    data: () => ({
        tableListData: [], // tableListData 展示数据的data
        foldList: [], // 该数组中的值 都会在列表中进行隐藏  死亡名单
    }),
    computed: {
        foldAllList() {
            return this.tableListData.map((x) => x.__identity);
        },
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            required: true,
        },
    },
    watch: {
        data: {
            handler(val) {
                this.tableListData = this.formatConversion([], val);
            },
            immediate: true,
        },
    },
    methods: {
        toggleFoldingStatus(params) {
            this.foldList.includes(params.__identity)
                ? this.foldList.splice(this.foldList.indexOf(params.__identity), 1)
                : this.foldList.push(params.__identity);
        },
        toggleDisplayTr({row}) {
            for (let i = 0; i < this.foldList.length; i++) {
                let item = this.foldList[i];
                // 如果foldList中元素存在于 row.__family中，则该行隐藏。  如果该行的自身标识等于隐藏元素，则代表该元素就是折叠点
                if (row.__family.includes(item) && row.__identity !== item) {
                    return {display: 'none'};
                }
            }
            return '';
        },
        toggleFoldingClass(params) {
            if (!params.children) {
                return '';
            }
            if (params.children.length === 0) {
                return '';
            }
            return this.foldList.indexOf(params.__identity) === -1
                ? 'el-icon-remove-outline'
                : 'el-icon-circle-plus-outline';
        },
        formatConversion(parent, children, index = 0, family = [], elderIdentity = 'x') {
            // children如果长度等于0，则代表已经到了最低层
            // let page = (this.startPage - 1) * 10
            if (children && children.length > 0) {
                children.map((x, i) => {
                    // 设置 __level 标志位 用于展示区分层级
                    this.$set(x, '__level', index);
                    // 设置 __family 为家族关系 为所有父级，包含本身在内
                    this.$set(x, '__family', [...family, elderIdentity + '_' + i]);
                    // 本身的唯一标识  可以理解为个人的身份证咯 一定唯一。
                    this.$set(x, '__identity', elderIdentity + '_' + i);
                    parent.push(x);
                    // 如果仍有子集，则进行递归
                    if (x.children && x.children.length > 0) {
                        this.formatConversion(
                            parent,
                            x.children,
                            index + 1,
                            [...family, elderIdentity + '_' + i],
                            elderIdentity + '_' + i
                        );
                    }
                });
            }
            return parent;
        },
    },
};
</script>

<style lang='less' scoped>
.collapse-icon {
    font-size: 18px;
    vertical-align: middle;
    &:hover {
        cursor: pointer;
        color: #486dd5;
    }
}
</style>
